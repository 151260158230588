const ERRORS = {
    NETWORK_ERROR: {
      message: 'Network down kindly try again.',
      name: 'NOT_FOUND'
    },
    REQUEST_FAIL: {
      message: 'Something went wrong.Please try again.',
      name: 'Request Fail'
    },
    SERVER_CONFLICT: {
      message: 'Unexpected server conflict.',
      name: 'SERVER_ERROR'
    },
    SERVER_ERROR: {
      message: 'Unexpected server error.',
      name: 'SERVER_ERROR'
    },
    USER_NOT_FOUND: {
      message: 'User not found.',
      name: 'USER_NOT_FOUND'
    },
    PASSWORD_EXPIRY_ERROR: {
      message: 'Your password has expired. Kindly reset the password.',
      name: 'Password expiry error'
    },    
    NOT_AUTHENTICATED: {
      message: 'Invalid credentials.',
      name: 'NOT_AUTHENTICATED'
    },
    KHIS_NOT_AUTHENTICATED: {
      message: 'Username or Password is incorrect',
      name: 'KHIS_NOT_AUTHENTICATED'
    },
    UNAUTHORIZED: {
      message: 'You don\'t have permission to perform this operation.',
      name: 'UNAUTHORIZED'
    }
  };

export default ERRORS;
