export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const USER_FORGOT_PASSWORD_REQUEST = 'USER_FORGOT_PASSWORD_REQUEST';
export const USER_FORGOT_PASSWORD_SUCCESS = 'USER_FORGOT_PASSWORD_SUCCESS';
export const USER_FORGOT_PASSWORD_FAIL = 'USER_FORGOT_PASSWORD_FAIL';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const GET_USERNAME_FOR_PASSWORD_RESET = 'GET_USERNAME_FOR_PASSWORD_RESET';
export const GET_USERNAME_FOR_PASSWORD_RESET_SUCCESS = 'GET_USERNAME_FOR_PASSWORD_RESET_SUCCESS';
export const GET_USERNAME_FOR_PASSWORD_RESET_FAIL = 'GET_USERNAME_FOR_PASSWORD_RESET_FAIL';

export const CREATE_PASSWORD_REQUEST = 'CREATE_PASSWORD_REQUEST';
export const CREATE_PASSWORD_SUCCESS = 'CREATE_PASSWORD_SUCCESS';
export const CREATE_PASSWORD_FAIL = 'CREATE_PASSWORD_FAIL';

export const FETCH_TIMEZONE_LIST_REQUEST = 'FETCH_TIMEZONE_LIST_REQUEST';
export const FETCH_TIMEZONE_LIST_SUCCESS = 'FETCH_TIMEZONE_LIST_SUCCESS';
export const FETCH_TIMEZONE_LIST_FAILURE = 'FETCH_TIMEZONE_LIST_FAILURE';

export const FETCH_LOGGED_IN_USER_REQUEST = 'FETCH_LOGGED_IN_USER_REQUEST';
export const FETCH_LOGGED_IN_USER_SUCCESS = 'FETCH_LOGGED_IN_USER_SUCCESS';
export const FETCH_LOGGED_IN_USER_FAILURE = 'FETCH_LOGGED_IN_USER_FAILURE';

export const SESSION_TIMEDOUT = 'SESSION_TIMEDOUT';
export const RESET_STORE = 'RESET_STORE';

export const FETCH_DEFAULT_ROLE = 'FETCH_DEFAULT_ROLE';
export const FETCH_DEFAULT_ROLE_SUCCESS = 'FETCH_DEFAULT_ROLE_SUCCESS';
export const FETCH_DEFAULT_ROLE_FAILURE = 'FETCH_DEFAULT_ROLE_FAILURE';

export const FETCH_COUNTRY_NAME = 'FETCH_COUNTRY_NAME';
export const CLEAR_COUNTRY_NAME = 'CLEAR_COUNTRY_NAME';
export const CLEAR_COUNTRY_ID = 'CLEAR_COUNTRY_ID';

export const FETCH_IS_KENYA = 'FETCH_IS_KENYA';
export const CLEAR_IS_KENYA = 'CLEAR_IS_KENYA';

export const EXPIRED_CHANGE_PASSWORD_REQUEST = 'EXPIRED_CHANGE_PASSWORD_REQUEST';
export const EXPIRED_CHANGE_PASSWORD_SUCCESS = 'EXPIRED_CHANGE_PASSWORD_SUCCESS';
export const EXPIRED_CHANGE_PASSWORD_FAILURE = 'EXPIRED_CHANGE_PASSWORD_FAILURE';

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL';

export const MFA_STATUS_REQUEST = 'MFA_STATUS_REQUEST';
export const MFA_STATUS_SUCCESS = 'MFA_STATUS_SUCCESS';
export const MFA_STATUS_FAILURE = 'MFA_STATUS_FAILURE';

export const MFA_SEND_OTP_REQUEST = 'MFA_SEND_OTP_REQUEST';
export const MFA_SEND_OTP_SUCCESS = 'MFA_SEND_OTP_SUCCESS';
export const MFA_SEND_OTP_FAILURE = 'MFA_SEND_OTP_FAILURE';

