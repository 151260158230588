import React from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Dispatch } from 'redux';

import logo from '../../assets/images/app-logo.svg';
import { AppState } from '../../store/rootReducer';

import { error, info } from '../../utils/toastCenter';
import styles from './Authentication.module.scss';
import APPCONSTANTS from '../../constants/appConstants';
import {
  getUserName,
  resetPassword,
  createPasswordRequest
} from '../../store/user/actions';
import { PUBLIC_ROUTES } from '../../constants/route';
import ResetPasswordFields, { generatePassword } from './ResetPasswordFields';

interface IDispatchProps {
  createPassword: ({
    email,
    password,
    token,
    successCB
  }: {
    email: string;
    password: string;
    token: string;
    successCB: () => void;
  }) => void;
  getUserName: (data: {
    token: string;
    successCB: () => void;
    failureCB: () => void;
  }) => void;
  resetPassword: (data: {
    email: string;
    password: string;
    token: string;
    successCB: () => void;
  }) => void;
}

interface IStateProps {
  loading: boolean;
  isPasswordSet: boolean;
  email: string;
  username: string;
}

interface IRouteProps extends RouteComponentProps<{ token: string }> { }

type Props = IRouteProps & IDispatchProps & IStateProps;

interface IResetPasswordState {
  isResetPassword: boolean;
  token: string;
  isShowPassword: boolean;
  isShowConfirmPassword: boolean;
}
class ResetPassword extends React.PureComponent<Props, IResetPasswordState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isResetPassword: false,
      token: '',
      isShowPassword: false,
      isShowConfirmPassword: false
    };
  }

  componentDidMount() {
    const url = this.props.history.location.search;
    const params = new URLSearchParams(url);
    const isResetPassword = Boolean(params.get('reset_password'));
    this.setState({ isResetPassword });
    let canRequest: boolean = true;
    if (isResetPassword) {
      const expiresValue = params.get('expires');
      const expiresTime = Number(expiresValue);
      if (expiresTime < new Date().getTime()) {
        canRequest = false;
        info('', APPCONSTANTS.LINK_EXPIRED);
        this.backToLogin();
      }
    }
    if (canRequest) {
      this.getUsername();
    }
  }

  backToLogin = () => {
    this.props.history.push({ pathname: PUBLIC_ROUTES.login });
  };

  getUsername = () => {
    const { token } = this.props.match.params;
    this.setState({ token });
    this.props.getUserName({ token, successCB: this.showToast, failureCB: this.failureCB });
  };

  showToast = () => {
    const { isResetPassword } = this.state;
    if (this.props.isPasswordSet && isResetPassword) {
      info('', APPCONSTANTS.PASSWORD_ALREADY_CHANGED);
      this.backToLogin();
    } else if (this.props.isPasswordSet) {
      info('', APPCONSTANTS.ACCOUNT_ALREADY_ACTIVATED);
      this.backToLogin();
    }
  };

  failureCB = () => {
    error(APPCONSTANTS.OOPS, APPCONSTANTS.PASSWORD_SET_FAILED);
  }

  onSubmitForm = (values: any) => {
    const email = values.username;
    const password = generatePassword(values.newPassword);
    const { token, isResetPassword } = this.state;
    if (isResetPassword) {
      this.props.resetPassword({
        email,
        password,
        token,
        successCB: this.backToLogin
      });
    } else {
      this.props.createPassword({
        email,
        password,
        token,
        successCB: this.backToLogin
      });
    }
  };

  render() {
    const { email } = this.props;
    return (
      <div className={styles.loginPage}>
        <div className={styles.loginFormContainer}>
          <div className={`${styles.brand} text-center`}>
            <img src={logo} alt='Medtronics' />
          </div>
          <div className={`primary-title text-center ${styles.loginTitle}`}>
            Reset your password
          </div>
          <Form
            onSubmit={this.onSubmitForm}
            initialValues={{ email }}
            render={({ handleSubmit, form }) => {
              const formState = form.getState();
              return (
                <form onSubmit={handleSubmit}>
                  <ResetPasswordFields email={email} />
                  <button disabled={!formState?.valid} type='submit' className='mt-2 btn primary-btn w-100'>
                    Submit
                  </button>
                </form>
              );
            }}
          />
          <div className={styles.backToLoginFooter} onClick={this.backToLogin}>
            <Link to={PUBLIC_ROUTES.login}>Go to login page</Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  loading: state.user.loading,
  isPasswordSet: state.user.isPasswordSet,
  email: state.user.email,
  username: state.user.username || ''
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createPassword: ({
    email,
    password,
    token,
    successCB
  }: {
    email: string;
    password: string;
    token: string;
    successCB: () => void;
  }) => dispatch(createPasswordRequest({ email, password }, token, successCB)),
  getUserName: ({
    token,
    successCB,
    failureCB
  }: {
    token: string;
    successCB: () => void;
    failureCB: () => void;
  }) => dispatch(getUserName(token, successCB, failureCB)),
  resetPassword: (data: {
    email: string;
    password: string;
    token: string;
    successCB: () => void;
  }) => dispatch(resetPassword(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
