import APPCONSTANTS from '../constants/appConstants';

export const formatDate = (date: any, char: string) => {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();
  if (day < 10) { day = '0' + day; }
  if (month < 10) { month = '0' + month; }
  if (char === 'slash') {
    date = day + '/' + month + '/' + year;
  } else if (char === 'dot') {
    date = day + '.' + month + '.' + year;
  }
  return date;
};

export const getFormattedDate = (report: any) => {
  const monthIndex = APPCONSTANTS.MONTH.findIndex((month) => {
    return month.name === report.month;
  });
  let startMonth: number | string;
  let endMonth: number | string;
  let endYear: number | string;
  const monthInd = monthIndex + 2 < 10 ? '0' + (monthIndex + 2) : monthIndex + 2
  startMonth = monthIndex + 1 < 10 ? '0' + (monthIndex + 1) : monthIndex + 1;
  endMonth = monthIndex + 2 === 13 ? '01' : monthInd;
  endYear = monthIndex + 2 === 13 ? report.year + 1 : report.year;
  const sDate = report.year + '-' + startMonth + '-01T00:00:00.000Z';
  const eDate = endYear + '-' + endMonth + '-01T00:00:00.000Z';
  return [sDate, eDate];
};

