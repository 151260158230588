import styles from './Authentication.module.scss';
import { Form, Field } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import TextInput from '../../components/formFields/TextInput';
import logo from '../../assets/images/app-logo.svg';
import { PUBLIC_ROUTES } from '../../constants/route';
import { loginRequest, mfaSendOTP } from '../../store/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import { phoneNumberSelector, remainingAttemptsSelector } from '../../store/user/selectors';
import { useCallback, useEffect, useState } from 'react';
import { convertToNumber } from '../../utils/validation';
import APPCONSTANTS from '../../constants/appConstants';
import toastCenter, { getErrorToastArgs } from '../../utils/toastCenter';

const MFA = (props: any) => {
  const dispatch = useDispatch();
  const username = props.location.state?.email || '';
  const password = props.location.state?.password || '';
  const rememberMe = false;
  const [isPageRefreshed, setIsPageRefreshed] = useState(false);
  const [counter, setCounter] = useState(APPCONSTANTS.COUNTER_SECONDS);
  const remainingAttempts = useSelector(remainingAttemptsSelector);
  const phoneNumber = useSelector(phoneNumberSelector);
  const history = useHistory();

  const onSubmitForm = useCallback(
    (values: any) => {
      dispatch(
        loginRequest({
          username,
          password,
          rememberMe,
          otp: values.otp,
          failureCb: (e: Error) => {
            if (e) {
              history.push({ pathname: PUBLIC_ROUTES.login });
            }
            if (remainingAttempts === 0) {
              toastCenter.error(APPCONSTANTS.LOGIN_FAILED_TITLE, APPCONSTANTS.OTP_LIMIT_EXCEEDED);
            } else {
              toastCenter.error(...getErrorToastArgs(e, APPCONSTANTS.LOGIN_FAILED_TITLE, e?.message));
            }
          }
        })
      );
    },
    [dispatch, history, password, remainingAttempts, rememberMe, username]
  );

  const reSendOTP = useCallback(() => {
    dispatch(
      mfaSendOTP({
        username,
        successCb: () => {},
        failureCb: (e: Error) => {
          if (e) {
            history.push({ pathname: PUBLIC_ROUTES.login });
            toastCenter.error(APPCONSTANTS.LOGIN_FAILED_TITLE, e?.message);
          }
        }
      })
    );
    setCounter(APPCONSTANTS.COUNTER_SECONDS);
  }, [dispatch, history, username]);

  useEffect(() => {
    reSendOTP();
  }, [reSendOTP]);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), APPCONSTANTS.SETTIMEOUT_COUNT);
  }, [counter]);

  const handlePageRefresh = () => {
    // Redirect to the '/' route path
    window.location.replace('/');
  };

  useEffect(() => {
    // Check if the component is being refreshed
    const isRefreshed =
      window.performance && window.performance.navigation.type === window.performance.navigation.TYPE_RELOAD;

    if (isRefreshed && !isPageRefreshed) {
      setIsPageRefreshed(true);
      handlePageRefresh();
    }
  }, [isPageRefreshed]);

  return (
    <div className={styles.loginPage}>
      <div className={styles.loginFormContainer}>
        <div className={`${styles.brand} text-center`}>
          <img src={logo} alt='Medtronics' />
        </div>
        <div className={`primary-title text-center ${styles.loginTitle}`}>
          Please enter the security code sent to {phoneNumber}
        </div>
        <Form
          onSubmit={onSubmitForm}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name='otp'
                type='text'
                parse={convertToNumber}
                render={({ input, meta }) => (
                  <TextInput
                    {...input}
                    errorLabel='otp'
                    required={false}
                    error={(meta.touched && meta.error) || undefined}
                  />
                )}
              />
              <p>
                {`Didn't get the code? `}
                <span
                  className={counter === 0 ? styles.reSendOtp : styles.beforeTimer}
                  onClick={counter === 0 ? reSendOTP : undefined}
                >
                  Resend SMS {counter !== 0 && `${counter}s`}
                </span>
              </p>
              <p className={`link-text ${styles.remainingAttempts}`}>Remaining Attempts:{remainingAttempts}</p>
              <button type='submit' className='mt-2 btn primary-btn w-100' disabled={!values.otp}>
                Submit
              </button>
            </form>
          )}
        />
      </div>
    </div>
  );
};

export default MFA;
