import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import Loader from './components/loader/Loader';
import Header from './components/header/Header';
import { AppRoutes } from './routes';
import {
  getIsLoggedInSelector,
  getIsLoggingInSelector,
  getIsLoggingOutSelector,
  getPasswordMessageSelector
} from './store/user/selectors';
import './App.scss';
import { useEffect, useState } from 'react';
import sessionStorageServices from './global/sessionStorageServices';
import APPCONSTANTS from './constants/appConstants';
import ResetPasswordFields, { generatePassword } from './containers/authentication/ResetPasswordFields';
import toastCenter, { getErrorToastArgs } from './utils/toastCenter';
import { expiredChangePassword, updatePassword } from './store/user/actions';
import ModalForm from './components/modal/ModalForm';


const App = () => {
  const dispatch = useDispatch();
  const loggingIn = useSelector(getIsLoggingInSelector);
  const loggedIn = useSelector(getIsLoggedInSelector);
  const loggingOut = useSelector(getIsLoggingOutSelector);
  const tenantId = sessionStorageServices.getItem(APPCONSTANTS.TENANT_ID);
  const isExpired = sessionStorageServices.getItem(APPCONSTANTS.IS_EXPIRED);
  const [isModalAction, setIsModalAction] = useState(false);
  const userName = sessionStorageServices.getItem(APPCONSTANTS.USERNAME);
  const passwordMessage = useSelector(getPasswordMessageSelector);
  const [passwordModal, setPasswordModal] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);

  const passwordChange = () => {
    setPasswordModal(true);
  };

  const buttonArray = [
    {
      title: 'Change password',
      className: 'primary-btn',
      onClick: passwordChange,
      type: 'button'
    },
    {
      title: 'Continue',
      className: 'primary-btn',
      onClick: () => {
        modalClose(true);
      },
      type: 'button'
    }
  ];

  useEffect(() => {
    setPasswordModal(isExpired);
  }, [isExpired]);

  useEffect(() => {
    loggedIn &&
      passwordMessage &&
      setIsModalAction(false);
  }, [loggedIn, passwordMessage]);

  const modalClose = (value: boolean) => {
    setIsModalAction(value);
  };

  const onModalCancel = () => {
    setPasswordModal(false);
    modalClose(true);
    sessionStorageServices.deleteItem(APPCONSTANTS.IS_EXPIRED);
  };
  const handleFormSubmit = (data: any) => {
    const oldPassword = generatePassword(data.oldPassword);
    const newPassword = generatePassword(data.newPassword);
    const payload = {
      user: userName,
      oldPassword,
      newPassword,
      tenantId,
      successCB: () => {
        toastCenter.success(APPCONSTANTS.SUCCESS, APPCONSTANTS.PASSWORD_CHANGE_SUCCESS);
        onModalCancel();
      },
      failureCb: (e: any) => {
        toastCenter.error(...getErrorToastArgs(e, APPCONSTANTS.ERROR, APPCONSTANTS.PASSWORD_CHANGE_FAILED));
      }
    };
    dispatch(isExpired ? expiredChangePassword(payload) : updatePassword(payload))
  };


  return (
    <div className='app-container'>
      {loggedIn ? <Header /> : null}
      <div className={`app-body ${loggedIn ? 'logged-in' : ''}`}>
        {loggingIn || loggingOut ? <Loader /> : null}
        <AppRoutes />
      </div>
      <ToastContainer />
      {passwordMessage && (
        <ModalForm
          show={!isModalAction}
          title={'Alert'}
          cancelText={''}
          autoHeight={true}
          submitText={''}
          isCancelButtonNeeded={false}
          handleCancel={() => {
            modalClose(true);
          }}
          handleFormSubmit={() => { }}
          size={'modal-md'}
          buttonArray={buttonArray}
        >
          <>{passwordMessage}</>
        </ModalForm>
      )}
      <ModalForm
        show={passwordModal}
        title={'Change Password'}
        cancelText={'Cancel'}
        submitText={'Submit'}
        autoHeight={true}
        handleCancel={onModalCancel}
        handleFormSubmit={handleFormSubmit}
        size={'modal-md'}
        isDisabled={!submitEnabled}
      >
        <ResetPasswordFields email={userName} setSubmitEnabled={setSubmitEnabled} adminPasswordChange={true} />
      </ModalForm>
    </div>
  );
};

export default App;
