import * as USER_TYPES from './actionTypes';
import {
  ILoginSuccessPayload,
  ILoginFailurePayload,
  ILoginRequest,
  ILoginSuccess,
  ILoginFailure,
  ILogoutRequest,
  ILogoutSuccess,
  ILogoutFailure,
  IForgotPasswordReq,
  IForgotPasswordSuccess,
  IForgotPasswordFailure,
  IResetPasswordReq,
  IResetPasswordSuccess,
  IResetPasswordFail,
  ICreatePasswordReq,
  ICreatePasswordSuccess,
  ICreatePasswordFail,
  IGetUserNameReq,
  IGetUserNameSuccess,
  IGetUserNameFail,
  ILoginRequestPayload,
  IFetchTimezoneListSuccessPayload,
  IUser,
  IRole,
  IExpiredChangePasswordSuccess,
  IExpiredChangePasswordFail,
  IMFAStatusRequest,
  IMFASendOTPRequest,
  IExpiredChangePasswordReq,
  IUpdatePasswordReq,
  IUpdatePasswordSuccess,
  IUpdatePasswordFail
} from './types';

export const loginRequest = ({
  username,
  password,
  rememberMe,
  otp,
  successCb,
  failureCb
}: ILoginRequestPayload): ILoginRequest => ({
  type: USER_TYPES.LOGIN_REQUEST,
  username,
  password,
  rememberMe,
  otp,
  successCb,
  failureCb
});

export const loginSuccess = (payload: ILoginSuccessPayload): ILoginSuccess => ({
  type: USER_TYPES.LOGIN_SUCCESS,
  payload
});

export const loginFailure = (payload: ILoginFailurePayload): ILoginFailure => ({
  type: USER_TYPES.LOGIN_FAILURE,
  payload
});

export const logoutRequest = (): ILogoutRequest => ({
  type: USER_TYPES.LOGOUT_REQUEST
});

export const logoutSuccess = (): ILogoutSuccess => ({
  type: USER_TYPES.LOGOUT_SUCCESS
});

export const logoutFailure = (): ILogoutFailure => ({
  type: USER_TYPES.LOGOUT_FAILURE
});

export const forgotPasswordRequest = ({
  email,
  successCB
}: {
  email: string;
  successCB: () => void;
}): IForgotPasswordReq => ({
  type: USER_TYPES.USER_FORGOT_PASSWORD_REQUEST,
  email,
  successCB
});

export const forgotPasswordSuccess = (): IForgotPasswordSuccess => ({
  type: USER_TYPES.USER_FORGOT_PASSWORD_SUCCESS
});

export const forgotPasswordFail = (error: any): IForgotPasswordFailure => ({
  type: USER_TYPES.USER_FORGOT_PASSWORD_FAIL,
  error
});

export const resetPassword = (data: {
  email: string;
  password: string;
  token: string;
  successCB: () => void;
}): IResetPasswordReq => ({
  type: USER_TYPES.RESET_PASSWORD_REQUEST,
  data
});

export const resetPasswordSuccess = (): IResetPasswordSuccess => ({
  type: USER_TYPES.RESET_PASSWORD_SUCCESS
});

export const resetPasswordFail = (error: any): IResetPasswordFail => ({
  type: USER_TYPES.RESET_PASSWORD_FAIL,
  error
});

export const getUserName = (token: string, successCB: () => void, failureCB: () => void): IGetUserNameReq => ({
  type: USER_TYPES.GET_USERNAME_FOR_PASSWORD_RESET,
  token,
  successCB,
  failureCB
});

export const getUserNameSuccess = (response: {
  email: string;
  username: string;
  isPasswordSet: boolean;
}): IGetUserNameSuccess => ({
  type: USER_TYPES.GET_USERNAME_FOR_PASSWORD_RESET_SUCCESS,
  response
});

export const getUserNameFail = (error: any): IGetUserNameFail => ({
  type: USER_TYPES.GET_USERNAME_FOR_PASSWORD_RESET_FAIL,
  error
});

export const createPasswordRequest = (
  data: { email: string; password: string },
  id: string,
  successCB: () => void
): ICreatePasswordReq => ({
  type: USER_TYPES.CREATE_PASSWORD_REQUEST,
  data,
  id,
  successCB
});

export const updatePassword = (data: {
  user: string;
  oldPassword: string;
  newPassword: string;
  tenantId: string;
  successCB: () => void;
  failureCb?: (error: Error) => void;
}): IUpdatePasswordReq => ({
  type: USER_TYPES.UPDATE_PASSWORD_REQUEST,
  data
});

export const updatePasswordSuccess = (): IUpdatePasswordSuccess => ({
  type: USER_TYPES.UPDATE_PASSWORD_SUCCESS
});

export const updatePasswordFail = (error: any): IUpdatePasswordFail => ({
  type: USER_TYPES.UPDATE_PASSWORD_FAIL,
  error
});


export const expiredChangePassword = (data: {
  user: string;
  oldPassword: string;
  newPassword: string;
  tenantId: string;
  successCB: () => void;
  failureCb?: (error: Error) => void;
}): IExpiredChangePasswordReq => ({
  type: USER_TYPES.EXPIRED_CHANGE_PASSWORD_REQUEST,
  data
});

export const expiredChangePasswordSuccess = (): IExpiredChangePasswordSuccess => ({
  type: USER_TYPES.EXPIRED_CHANGE_PASSWORD_SUCCESS
});

export const expiredChangePasswordFail = (error: any): IExpiredChangePasswordFail => ({
  type: USER_TYPES.EXPIRED_CHANGE_PASSWORD_FAILURE,
  error
});

export const mfaStatus = (username: string, mfaEnabledCB: (mfaStatus: boolean) => void): IMFAStatusRequest => ({
  type: USER_TYPES.MFA_STATUS_REQUEST,
  username,
  mfaEnabledCB
});

export const mfaStatusFailure = () => ({
  type: USER_TYPES.MFA_STATUS_FAILURE
});

export const mfaStatusSuccess = (payload: any) => ({
  type: USER_TYPES.MFA_STATUS_SUCCESS,
  payload
});

export const mfaSendOTP = ({
  username,
  successCb,
  failureCb
}: {
  username: string;
  successCb?: () => void;
  failureCb?: (error: Error) => void;
}): IMFASendOTPRequest => ({
  type: USER_TYPES.MFA_SEND_OTP_REQUEST,
  username,
  successCb,
  failureCb
});

export const mfaSendOTPFailure = () => ({
  type: USER_TYPES.MFA_SEND_OTP_FAILURE
});

export const mfaSendOTPSuccess = (payload: any) => ({
  type: USER_TYPES.MFA_SEND_OTP_SUCCESS,
  payload
});


export const createPasswordSuccess = (): ICreatePasswordSuccess => ({
  type: USER_TYPES.CREATE_PASSWORD_SUCCESS
});

export const createpasswordFail = (error: ILoginFailurePayload): ICreatePasswordFail => ({
  type: USER_TYPES.CREATE_PASSWORD_FAIL,
  error
});

export const fetchTimezoneListRequest = () => ({
  type: USER_TYPES.FETCH_TIMEZONE_LIST_REQUEST
});

export const fetchTimezoneListSuccess = (payload: IFetchTimezoneListSuccessPayload) => ({
  type: USER_TYPES.FETCH_TIMEZONE_LIST_SUCCESS,
  payload
});

export const fetchTimezoneListFailure = () => ({
  type: USER_TYPES.FETCH_TIMEZONE_LIST_FAILURE
});

export const sessionTimedout = (message: string) => ({
  type: USER_TYPES.SESSION_TIMEDOUT,
  message
});

export const resetStore = () => ({
  type: USER_TYPES.RESET_STORE
});

export const fetchLoggedInUser = () => ({
  type: USER_TYPES.FETCH_LOGGED_IN_USER_REQUEST
});

export const fetchLoggedInUserSuccess = (payload: IUser) => ({
  type: USER_TYPES.FETCH_LOGGED_IN_USER_SUCCESS,
  payload
});

export const fetchLoggedInUserFail = () => ({
  type: USER_TYPES.FETCH_LOGGED_IN_USER_FAILURE
});

export const fetchDefaultRole = () => ({
  type: USER_TYPES.FETCH_DEFAULT_ROLE
});

export const fetchDefaultRoleSuccess = (data: IRole) => ({
  type: USER_TYPES.FETCH_DEFAULT_ROLE_SUCCESS,
  data
});

export const fetchDefaultRoleFailure = () => ({
  type: USER_TYPES.FETCH_DEFAULT_ROLE_FAILURE
});

export const fetchCountryName = () => ({
  type: USER_TYPES.FETCH_COUNTRY_NAME
});

export const fetchIsKenya = () => ({
  type: USER_TYPES.FETCH_IS_KENYA
});

export const clearIsKenya = () => ({
  type: USER_TYPES.CLEAR_IS_KENYA
});

export const clearCountryName = () => ({
  type: USER_TYPES.CLEAR_COUNTRY_NAME
});

export const clearCountryId = () => ({
  type: USER_TYPES.CLEAR_COUNTRY_ID
});
