import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import ERRORS from '../../constants/error';
import APPCONSTANTS from '../../constants/appConstants';
import DetailCard from '../../components/detailCard/DetailCard';
import CustomTable from '../../components/customtable/CustomTable';
import { screenedListSelector, loadingSelector, totalSelector } from '../../store/report/selectors';
import { fetchScreenedPatientListRequest } from '../../store/report/actions';
import toastCenter from '../../utils/toastCenter';
import Loader from '../../components/loader/Loader';
import FilterLayout from '../../components/filter/FilterLayout';
import { downloadScreenedPatientsReport } from '../../services/reportAPI';

interface IMatchParams {
  siteId: string;
  siteName: string;
}

const ScreenedReport = (props: IMatchParams): React.ReactElement => {
  const fromDatePickerRef = useRef<DatePicker>(null);
  const toDatePickerRef = useRef<DatePicker>(null);
  const [listParams, setListReqParams] = useState({
    page: APPCONSTANTS.NUMBER.ONE,
    rowsPerPage: APPCONSTANTS.ROWS_PER_PAGE_OF_TABLE,
    searchTerm: ''
  });

  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [gender, setGender] = useState<Array<string | null>>([]);
  const [HTNDiagnosis, setHTNDiagnosis] = useState(false);
  const [DBMDiagnosis, setDBMDiagnosis] = useState(false);
  const [refferedAssessment, setRefferedAssessment] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const dispatch = useDispatch();

  const screenedList = useSelector(screenedListSelector);
  const loading = useSelector(loadingSelector);
  const screenedCount = useSelector(totalSelector);
  const { siteId, siteName } = useParams<IMatchParams>();
  const dateInput = toDate ? fromDate : null;

  useEffect(() => {
    if (!fromDate) {
      toDatePickerRef.current?.props.onChange(null, undefined);
      setToDate(null);
    }
    if ((fromDate && !toDate) || (fromDate && toDate)) {
      toDatePickerRef.current?.props.minDate?.setDate(fromDate.getDate());
      toDatePickerRef.current?.props.minDate?.setMonth(fromDate.getMonth());
      toDatePickerRef.current?.props.minDate?.setFullYear(fromDate.getFullYear());
    }
    if (toDate) {
      fromDatePickerRef.current?.props.maxDate?.setDate(toDate.getDate());
      fromDatePickerRef.current?.props.maxDate?.setMonth(toDate.getMonth());
      fromDatePickerRef.current?.props.maxDate?.setFullYear(toDate.getFullYear());
    }
  }, [toDatePickerRef, fromDate, toDate, loading, exportLoading]);

  useEffect(() => {
    if (gender.length || (fromDate && toDate) || HTNDiagnosis || DBMDiagnosis || refferedAssessment) {
      setListReqParams((prevstate) => {
        return {
          ...prevstate,
          page: 1
        };
      });
    }
  }, [gender, fromDate , toDate, HTNDiagnosis, DBMDiagnosis, refferedAssessment]);

  const reqPayload = useCallback(() => {
    const reqData = {
      siteId: Number(siteId),
      skip: (listParams.page - 1) * listParams.rowsPerPage,
      limit: listParams.rowsPerPage,
      failureCb: (e: Error) => {
        if (e.message === ERRORS.NETWORK_ERROR.message) {
          toastCenter.error(APPCONSTANTS.NETWORK_ERROR, APPCONSTANTS.CONNECTION_LOST);
        } else {
          toastCenter.error(APPCONSTANTS.OOPS, APPCONSTANTS.SCREENED_PATIENTS_FETCH_ERROR);
        }
      }
    };
    if (fromDate && toDate) {
      Object.assign(reqData, {
        fromDate: moment(fromDate).format(APPCONSTANTS.YEAR_MONTH_DATE_FORMAT),
        toDate: moment(toDate).format(APPCONSTANTS.YEAR_MONTH_DATE_FORMAT)
      });
    }
    if (gender.length) {
      Object.assign(reqData, { genders: gender });
    }
    if (HTNDiagnosis) {
      Object.assign(reqData, { htnDiagnosis: HTNDiagnosis });
    }
    if (DBMDiagnosis) {
      Object.assign(reqData, { dbmDiagnosis: DBMDiagnosis });
    }
    if (refferedAssessment) {
      Object.assign(reqData, { referAssessment: refferedAssessment });
    }
    return reqData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateInput, toDate, siteId, HTNDiagnosis, DBMDiagnosis, refferedAssessment, listParams, gender]);

  useEffect(() => {
    dispatch(
      fetchScreenedPatientListRequest({ payload: reqPayload() })
    );
  }, [reqPayload, dispatch]);

  const handleSearch = (searchString: string) => {
    setListReqParams((prevstate) => {
      return {
        ...prevstate,
        page: 1,
        searchTerm: searchString
      };
    });
  };

  const handlePage = (pageNo: number, rowsPerPage: number | undefined = APPCONSTANTS.ROWS_PER_PAGE_OF_TABLE) => {
    setListReqParams((prevstate) => {
      return {
        ...prevstate,
        page: pageNo,
        rowsPerPage
      };
    });
  };

  const handleGenderOnChange = (event: React.BaseSyntheticEvent) => {
    if (event.target.checked) {
      setGender(values => [...values, event.target.value]);
    } else {
      const index = gender?.indexOf(event.target.value);
      const updatedValue = gender;
      updatedValue.splice(index, 1);
      setGender(values => [...updatedValue]);
    }
  };

  const exportScreenedPatients = async () => {
    try {
      setExportLoading(true);
      const payLoad = {
        siteId: Number(siteId),
        siteName,
        limit: screenedCount
      };
      if (fromDate && toDate) {
        Object.assign(payLoad, {
          fromDate: moment(fromDate).format(APPCONSTANTS.YEAR_MONTH_DATE_FORMAT),
          toDate: moment(toDate).format(APPCONSTANTS.YEAR_MONTH_DATE_FORMAT)
        });
      }
      if (gender.length) {
        Object.assign(payLoad, { genders: gender });
      }
      if (HTNDiagnosis) {
        Object.assign(payLoad, { htnDiagnosis: HTNDiagnosis });
      }
      if (DBMDiagnosis) {
        Object.assign(payLoad, { dbmDiagnosis: DBMDiagnosis });
      }
      if (refferedAssessment) {
        Object.assign(payLoad, { referAssessment: refferedAssessment });
      }
      await downloadScreenedPatientsReport(payLoad);
      setExportLoading(false);
      toastCenter.success(APPCONSTANTS.SUCCESS, APPCONSTANTS.REPORT_DOWNLOAD_SUCCESS);
    } catch {
      setExportLoading(false);
      toastCenter.error(APPCONSTANTS.ERROR, APPCONSTANTS.DOWNLOAD_FAIL_MSG);
    }
  };
  return (
    <>
      {loading && <Loader />}
      {exportLoading && <Loader />}
      <div className='row'>
        <FilterLayout
          fromDateSelector={(date: Date) => {
            setFromDate(date);
          }}
          toDateSelector={(date: Date) => setToDate(date)}
          handleGenderOnChange={(event: React.BaseSyntheticEvent) => handleGenderOnChange(event)}
          HTNOnChange={(event: React.BaseSyntheticEvent) => {
            setHTNDiagnosis(event.target.checked);
          }}
          DBMOnChange={(event: React.BaseSyntheticEvent) => {
            setDBMDiagnosis(event.target.checked);
          }}
          refferedAssessmentOnChange={(event: React.BaseSyntheticEvent) => {
            setRefferedAssessment(event.target.checked);
          }}
          fromDate={fromDate}
          fromDatePickerRef={fromDatePickerRef}
          toDatePickerRef={toDatePickerRef}
        />
        <div className='col'>
          <DetailCard
            header='Screened Patients'
            isSearch={false}
            onSearch={handleSearch}
            buttonLabel='Export'
            onButtonClick={() => exportScreenedPatients()}
            isDownload={true}
            isDisabled={screenedList.length === 0 ? true : false}
          >
            <CustomTable
              rowData={screenedList}
              columnsDef={[
                {
                  id: 1,
                  name: 'firstName',
                  label: 'Name'
                },
                {
                  id: 2,
                  name: 'gender',
                  label: 'Gender'
                },
                {
                  id: 3,
                  name: 'phoneNumber',
                  label: 'Phone Number',
                  class: 'numeric'
                },
                {
                  id: 5,
                  name: 'confirm_diagnosis',
                  label: 'Confirm Diagnosis',
                  cellFormatter: (data: any) => {
                    if (data?.confirmDiagnosis == null) {
                      return '';
                    } else {
                      return data.confirmDiagnosis;
                    }
                  }
                },
                {
                  id: 6,
                  name: 'refer_assessment',
                  label: 'Referred Assessment',
                  cellFormatter: (data: any) => {
                    if (!Object.is(data?.referAssessment, null)) {
                      return String(data.referAssessment);
                    } else {
                      return '';
                    }
                  }
                },
                {
                  id: 6,
                  name: 'created_at',
                  label: 'Created On',
                  class: 'numeric',
                  cellFormatter: (data: any) => {
                    if (data?.createdAt) {
                      return moment(data.createdAt).format(APPCONSTANTS.DATE_MONTH_YEAR_FORMAT);
                    } else {
                      return '';
                    }
                  }
                }
              ]}
              isDelete={false}
              isEdit={false}
              isView={false}
              isDownload={false}
              page={listParams.page}
              rowsPerPage={listParams.rowsPerPage}
              count={screenedCount}
              handlePageChange={handlePage}
            />
          </DetailCard>
        </div>
      </div>
    </>
  );
};

export default ScreenedReport;
