import APPCONSTANTS from '../constants/appConstants';
import CryptoJS from 'crypto-js';

const getEncryptionKey = () => {
  return CryptoJS.PBKDF2(
    (process.env.REACT_APP_CRYPTR_SECRET_KEY as string) || '',
    (process.env.REACT_APP_ENCRYPTION_SALT as string) || '',
    {
      keySize: APPCONSTANTS.ENCRYPTION.KEYLEN / 32,
      iterations: APPCONSTANTS.ENCRYPTION.ITERATION
    }
  );
};

export const decryptData = (password: string) => {
  const key = getEncryptionKey();
  const iv = CryptoJS.enc.Utf8.parse((process.env.REACT_APP_ENCRYPTION_SALT as string) || '');
  return CryptoJS.AES.decrypt(password, key, { iv }).toString(CryptoJS.enc.Utf8);
};

export const encryptData = (value: string) => {
  const key = getEncryptionKey();
  const iv = CryptoJS.enc.Utf8.parse((process.env.REACT_APP_ENCRYPTION_SALT as string) || '');
  return CryptoJS.AES.encrypt(value, key, { iv }).toString();
};

/**
 * Appends zero before given number if number of digitd is less that minimumIntegerDigits
 * @param num
 * @param minimumIntegerDigits
 * @returns {string}
 */
export const appendZeroBefore = (
  num: number,
  minimumIntegerDigits: number
): string =>
  (Number(num) || 0).toLocaleString('en-US', {
    minimumIntegerDigits,
    useGrouping: false
  });

/**
 * A utility function to stop the event from propogating up on DOM
 * @param e
 */
export const stopPropogation = (e: React.BaseSyntheticEvent) => {
  try {
    e.stopPropagation();
  } catch (error) {
    console.error(error);
  }
};

/**
 * A utility function to check difference of two arrays
 */
export const differenceOfArray = (ary1: any, ary2: any) =>
  ary1.filter((value: any) => !ary2.includes(value));
