import axios from 'axios';
import APPCONSTANTS from '../constants/appConstants';
import sessionStorageServices from '../global/sessionStorageServices';

export const login = (username: string, password: string, otp: string) => {
  const loginData = new FormData();
  loginData.append('username', username);
  loginData.append('password', password);
  loginData.append('otp', otp || '');
  return axios({
    method: 'POST',
    url: 'auth-service/session',
    data: loginData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'client': 'cfr web'
    }
  });
};

export const logout = () =>
  axios({
    method: 'GET',
    url: 'auth-service/logout'
  });

export const forgotPassword = (email: string) =>
  axios({
    method: 'post',
    url: `/user-service/user/forgot-password`,
    data: { username: email, appType: APPCONSTANTS.APP_TYPE }
  });

export const resetPasswordReq = (
  data: { email: string; password: string },
  token: string
) =>
  axios({
    method: 'POST',
    url: `user-service/user/reset-password/${token}`,
    data: {
      ...data
    }
  });

export const getUsername = (token: string) =>
  axios({
    method: 'get',
    url: `user-service/user/verify-set-password/${token}`
  });

export const createPassword = (data: { password: string }, token: string) =>
  axios({
    method: 'post',
    url: `user-service/user/set-password/${token}`,
    data: {
      password: data.password
    }
  });

export const fetchTimezoneList = () =>
  axios({
    method: 'GET',
    url: '/forms/timezone?$limit=null'
  });

export const fetchUserRoles = (token: string) =>
  axios({
    method: 'get',
    url: `user-service/user/get-user-roles?$token=${token}`
  });

export const fetchLoggedInUser = () =>
  axios({
    method: 'get',
    url: `user-service/user/logged-in-user`
  });

export const getDefaultRoles = () =>
  axios({
    method: 'get',
    url: 'user-service/user/roles'
  });

export const updatePassword = (data: { userId: string; oldPassword: string; newPassword: string; tenantId: string }) =>
axios({
  method: 'post',
  url: `user-service/user/change-admin-password`,
  data: {
    oldPassword: data.oldPassword,
    newPassword: data.newPassword,
    username: data.userId
  }, 
});  

export const expiredChangePasswordReq = (data: {
  userId: string;
  oldPassword: string;
  newPassword: string;
  tenantId: string;
}) =>
  axios({
    method: 'POST',
    url: `user-service/user/change-admin-expired-password`,
    data: {
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
      username: data.userId,
      token: sessionStorageServices.getItem(APPCONSTANTS.EXPIRED_TOKEN)
    }
  });

export const mfaStatus = (username: string) =>
  axios({
    method: 'POST',
    url: '/auth-service/auth/mfa-status',
    data: { username }
  });

export const mfaSendOTP = (username: string) =>
  axios({
    method: 'POST',
    url: '/auth-service/auth/send-otp',
    data: { username }
  });